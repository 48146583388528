import React from 'react';
import styled, { css } from 'styled-components';
import renderRichText from '@common/renderRichText';

import mq from '@mq';
import { richText, hyphenate } from '@mixins';

const BlockText = ({ width, text, fontSize }) => {
  if (!text || !text.raw) {
    return null;
  }

  return (
    <Container width={width} fontSize={fontSize}>
      {renderRichText(text)}
    </Container>
  );
};

const Container = styled.section`
  ${richText};
  ${hyphenate};
  font: var(--font-project-card-title);
  /* font: var(--font-h3); */

  ${props => {
    switch (props.fontSize) {
      case 'small':
        return css`
          font: var(--font-default);
        `;
      case 'large':
        return css`
          font: var(--font-h3);
        `;
      default:
      case 'medium':
        return css`
          font: var(--font-project-card-title);
        `;
    }
  }}

  ${mq.medium} {
    ${props => {
      switch (props.width) {
        case 'small':
          return css`
            width: 60%;
            margin: 0 auto;
          `;
        case 'medium':
          return css`
            width: 75%;
            margin: 0 auto;
          `;
        default:
        case 'large':
          return null;
      }
    }}
  }
`;

export default BlockText;
