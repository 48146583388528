import React from 'react';
import styled, { css } from 'styled-components';

import Image from '@core/Image';
import mq from '@mq';

const BlockImage = ({ width, images }) => {
  if (!images || images.length < 1) {
    return null;
  }

  return (
    <Container width={width} length={images.length}>
      {images.map(image => (
        <Image key={image.id} image={image} />
      ))}
    </Container>
  );
};

const Container = styled.section`
  display: grid;
  row-gap: var(--grid-gap);

  ${mq.medium} {
    grid-template-columns: repeat(${props => props.length}, 1fr);
    column-gap: var(--grid-gap);
    align-items: center;

    ${props => {
      switch (props.width) {
        case 'small':
          return css`
            width: 60%;
            margin: 0 auto;
          `;
        case 'medium':
          return css`
            width: 75%;
            margin: 0 auto;
          `;
        default:
        case 'large':
          return null;
      }
    }}
  }
`;

export default BlockImage;
