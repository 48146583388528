/* eslint-disable no-underscore-dangle */
import React from 'react';
import styled from 'styled-components';
import { Link as GatsbyLink, graphql } from 'gatsby';

import renderRichText from '@common/renderRichText';
import Seo from '@core/Seo';

import BlockImage from '@components/BlockImage';
import BlockText from '@components/BlockText';
import BackLink from '@components/BackLink';

import withModal from '@common/withModal';
import mq from '@mq';

const ProjectPage = ({
  modal,
  data: {
    contentfulProject: {
      author,
      location: { city, street },
      professor,
      university: { title: university },
      contentBlocks,
      seoImage: {
        gatsbyImageData: {
          images: {
            fallback: { src: seoImage },
          },
        },
      },
    },
  },
}) => (
  <>
    <Seo pageTitle={author.join(' - ')} pageImage={seoImage} />
    {!modal && <BackLink to="/entwuerfe" />}
    <Header>
      <div>
        {author.map((item, index) => (
          <div key={index}>{item}</div>
        ))}
      </div>
      <div>
        <div>{university}</div>
        <div>{professor}</div>
        <div>
          {street}, {city}
        </div>
      </div>
    </Header>
    {contentBlocks && (
      <ContentBlocks>
        {contentBlocks.map(contentBlock => {
          const Component = getBlock(contentBlock.__typename);
          return <Component {...contentBlock} key={contentBlock.id} />;
        })}
      </ContentBlocks>
    )}
  </>
);

const Header = styled.header`
  display: grid;
  row-gap: 0.75rem;
  margin-bottom: var(--space-double);

  ${mq.medium} {
    grid-template-columns: auto auto;
    column-gap: 5rem;
    justify-content: start;
    margin-bottom: 5rem;
  }
`;

const ContentBlocks = styled.div`
  display: grid;
  row-gap: 5rem;

  ${mq.medium} {
    row-gap: 10rem;
  }
`;

function getBlock(typename) {
  switch (typename) {
    case 'ContentfulBlockText':
      return BlockText;
    case 'ContentfulBlockImage':
      return BlockImage;
    default:
      return null;
  }
}

export const query = graphql`
  query ($id: String!) {
    contentfulProject(id: { eq: $id }) {
      author
      professor
      university {
        title
        shortTitle
      }
      location {
        city
        street
      }
      seoImage: image {
        gatsbyImageData(
          layout: FIXED
          width: 1200
          height: 630
          formats: JPG
          quality: 80
          resizingBehavior: FILL
        )
      }
      contentBlocks {
        ... on ContentfulBlockImage {
          __typename
          id
          width
          images {
            description
            id
            gatsbyImageData(
              layout: CONSTRAINED
              width: 720
              placeholder: DOMINANT_COLOR
              formats: [AUTO, WEBP]
              quality: 80
            )
          }
        }
        ... on ContentfulBlockText {
          __typename
          width
          id
          fontSize
          text {
            raw
          }
        }
      }
    }
  }
`;

export default withModal(ProjectPage);
